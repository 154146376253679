<template>
  <div>
    <div v-if="isLoaded" class="card">

      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid}">
        <form @submit.prevent="handleSubmit(submitForm)">

          <!-- Name -->
          <div class="max-w-sm">
            <ValidationProvider :name="$t('pages.culture_create.fields.name')" rules="required|max:255" v-slot="{errors}">
              <label class="form__label">{{ $t('pages.culture_create.fields.name') }}<Required/></label>
              <input v-model="form.name" class="form__input w-full" type="text">
              <p class="form__error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- Cultivation type (outside / inside) -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.cultureType') }}<Required/></label>
            <input id="radio-outside" type="radio" value="1" v-model.number="form.outside">
            <label for="radio-outside" class="text-gray-600"> {{ $t('pages.culture_create.fields.outside') }}</label>

            <input id="radio-inside" class="ml-4" type="radio" value="0" v-model.number="form.outside">
            <label for="radio-inside" class="text-gray-600"> {{ $t('pages.culture_create.fields.inside') }}</label>
          </div>

          <!-- Type - Variety -->
          <div class="mt-4 max-w-sm flex">

            <div class="w-full">
              <label class="form__label">{{ $t('pages.culture_create.fields.variety') }}<Required/></label>
              <select v-model.number="form.varietyId" class="form__input cursor-pointer">
                <optgroup v-for="cultureType in cultureTypes" :key="cultureType.id" :label="$t('backend_trans_keys.' + cultureType.typeTransKey)">
                  <option v-for="variety in cultureType.varieties" :key="variety.id" :value="variety.id">{{ $t('backend_trans_keys.' + variety.varietyTransKey) }}</option>
                </optgroup>
              </select>
            </div>

            <div  class="w-full">
              <ValidationProvider :name="$t('pages.culture_create.fields.nouaisonDate')" v-slot="{errors}">
                <label class="form__label">{{ $t('pages.culture_create.fields.nouaisonDate') }}</label>
                <input v-model="form.nouaisonDate" class="form__input w-full" type="date">
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

          </div>

          <!-- Interactive map -->
          <div class="mt-4">
            <label class="form__label">{{ $t('pages.culture_create.fields.interactiveMapType.title') }}<Required/></label>
            <select v-model.number="form.interactiveMapType" class="form__input cursor-pointer">
              <option value="0">{{ $t('pages.culture_create.fields.interactiveMapType.none') }}</option>
              <option value="1">{{ $t('pages.culture_create.fields.interactiveMapType.geo') }}</option>
              <option value="2">{{ $t('pages.culture_create.fields.interactiveMapType.custom') }}</option>
            </select>

            <!-- Upload custom map file -->
            <div v-if="form.interactiveMapType === 2" class="mt-4">
              <div v-if="culture.hasGeoMap === false" class="block">
                <label class="form__label">{{ $t('pages.culture_edit.actualMapImage') }} </label>
                <img :src="apiUploadMapsRoute+'/'+culture.mapImageName" width="100">
                <label class="form__label">{{ $t('pages.culture_edit.newMapImage') }} </label>
              </div>
              <div class="flex items-center">
                <!-- Full Drop zone -->
                <ValidationProvider name="fileDrop" v-slot="{errors}">
                  <file-drop-zone @onSuccessUploadFile="onSuccessUploadFile" @onError="onFileDropError" @onDeleteUploadFile="onDeleteUploadFile"/>
                  <!-- Error -->
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <!-- Validate button -->
          <button class="mt-6 btn" :class="{ 'btn--success': !invalid,'btn--disabled': invalid }"><i class="fas fa-check mr-2"></i>{{ $t('pages.culture_edit.validateBtn') }}</button>
        </form>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
import Required from "../../../components/forms/Required";
import {
  API_CULTURE_TYPES_INDEX,
  API_CULTURES_SHOW2, API_CULTURES_UPDATE, API_UPLOAD_MAPS_ROUTE
} from "../../../utils/constants";
import {apiGetRequest, apiPostRequest, displayAlertSuccess} from "../../../utils";
import FileDropZone from "../../../components/forms/FileDropZone";
import moment from "moment";

export default {
  name: "CultureEdit",
  components: {Required, FileDropZone},
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data: function() {
    return {
      isLoaded: false,
      form: {
        farmerId: 0,
        name: null,
        nouaisonDate: null,
        outside: 1,
        varietyId: -1,
        interactiveMapType: 0,
        newMapFile: null
      },
      culture: null,
      cultureTypes: null,
      apiUploadMapsRoute: API_UPLOAD_MAPS_ROUTE
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    /**
     * Load current culture data and all available varieties
     */
    loadData: function() {
      this.isLoaded = false
      let showCulturePromise = apiGetRequest(API_CULTURES_SHOW2(this.id))
      let allVarietiesPromise = apiGetRequest(API_CULTURE_TYPES_INDEX)

      Promise.all([showCulturePromise, allVarietiesPromise])
        .then((res) => {
          this.culture = res[0].data
          this.cultureTypes = res[1].data

          // Careful month in mysql are [1-12] and in js [0-11]
          let nouaisonDate = this.culture.nouaisonDate
          if(nouaisonDate !== null){
            nouaisonDate[1]--
            nouaisonDate = moment.utc(nouaisonDate).format("Y-MM-DD") // needed format for
          }

          // Fill the form with old value
          this.form.farmerId = this.culture.farmerId
          this.form.name = this.culture.name
          this.form.outside = this.culture.outside ? 1 : 0
          this.form.varietyId = this.culture.varietyId
          this.form.nouaisonDate = nouaisonDate
          this.form.interactiveMapType = this.culture.hasGeoMap === null ? 0 : this.culture.hasGeoMap === true ? 1 : 2

          this.isLoaded = true
          if(this.culture.hasGeoMap === false){
            try{
              document.getElementById('activeMap').style.backgroundImage = "url(http://localhost:3000/upload/maps/"+this.culture.mapImageName+")";
              // eslint-disable-next-line no-empty
            }catch (e) {}

          }
        })
    },
    onFileDropError: function(error){
      this.form.newMapFile = null

      const errors = this.$refs.form.errors
      errors['fileDrop'] = error.message
      this.$refs.form.setErrors(errors)
    },
    onSuccessUploadFile: function (file) {
      // Reset old file drop error
      const errors = this.$refs.form.errors
      errors['fileDrop'] = null
      this.$refs.form.setErrors(errors)

      this.form.newMapFile = file
    },
    onDeleteUploadFile: function(){
      this.form.newMapFile = null
    },
    /**
     * Submit the form
     */
    submitForm: function() {
      if(this.form.interactiveMapType === 2 && this.culture.mapImageName === null && this.form.newMapFile === null){
        const errors = this.$refs.form.errors
        errors['fileDrop'] = this.$t('pages.culture_create.errorMapFileRequired')
        this.$refs.form.setErrors(errors)
      }else{
        const formData = new FormData()
        formData.append('farmerId', this.form.farmerId)
        formData.append('name', this.form.name)
        formData.append('outside', this.form.outside)
        formData.append('varietyId', this.form.varietyId)
        if(this.form.nouaisonDate !== null){
          formData.append('nouaisonDate', this.form.nouaisonDate)
        }
        formData.append('interactiveMapType', this.form.interactiveMapType)
        if(this.form.interactiveMapType === 2 && this.form.newMapFile !== null){
          formData.append('customMapFile', this.form.newMapFile, this.form.newMapFile.name)
        }

        apiPostRequest(API_CULTURES_UPDATE(this.id), formData, true)
            .then(() => {
              const successMsg = this.$t('pages.culture_edit.success', {name: this.form.name})
              displayAlertSuccess(successMsg)
              this.$router.push({name: "culture_show", params: {id: this.id}})
            })
            .catch()
      }
    }
  }
}
</script>

<style scoped>
</style>
