<template>
  <div>
    <div v-if="$store.getters.userIsAdmin" class="flex flex-row-reverse mb-4">
      <router-link :to="{name:'user_create'}">
        <button class="btn btn--primary">{{$t('pages.user_index.create')}}</button>
      </router-link>
    </div>
    <!-- Admins -->
    <div v-if="admins.isLoaded" class="card">
      <h2 class="card__title">{{ $t('pages.user_index.cards.admins.title', {'count':admins.data.length}) }}</h2>
      <data-table v-if="admins.data" :columns="admins.columns" :data="admins.data" />
    </div>

    <!-- Farmers -->
    <div v-if="farmers.isLoaded" class="card mt-4 ">
      <h2 class="card__title">{{ $t('pages.user_index.cards.farmers.title', {'count':farmers.data.length}) }}</h2>
      <data-table v-if="farmers.data" :columns="farmers.columns" :data="farmers.data" />
    </div>

  </div>
</template>

<script>
import DataTable from "../../../components/general/datatable/data-table";
import {apiGetRequest} from "../../../utils";
import {API_USERS_ALL} from "../../../utils/constants";

export default {
  name: "UsersIndex",
  components: {DataTable},
  data: function() {
    return {
      admins: {
        isLoaded: false,
        data: null,
        columns: {
          firstname: this.$t('words.firstname'),
          lastname: this.$t('words.lastname'),
          email: this.$t('words.email'),
          street: this.$t('words.street'),
          npaCity: this.$t('words.npa-city'),
          nbDevices: this.$t('words.nbDevices'),
          actions: this.$t('words.actions'),
        },
      },
      farmers: {
        isLoaded: false,
        data: null,
        columns: {
          firstname: this.$t('words.firstname'),
          lastname: this.$t('words.lastname'),
          email: this.$t('words.email'),
          street: this.$t('words.street'),
          npaCity: this.$t('words.npa-city'),
          nbCultures: this.$t('words.nbCultures'),
          nbDevices: this.$t('words.nbDevices'),
          actions: this.$t('words.actions'),
        },
      },
    }
  },
  mounted() {
    this.loadUsers()
  },
  methods: {
    loadUsers: function() {
      apiGetRequest(API_USERS_ALL)
          .then((res) => {
            this.admins.data = res.data.admins.map((a) => {
              a.nbDevices = a.nbSensors + a.nbTransmitters
              a.npaCity = `${a.npa} ${a.city}`
              a.actions = {
                see: {
                  to: {name: 'users_inventory', params:{userIdProps: a.id}},
                  text: this.$t('pages.user_index.cards.admins.seeBtn')
                }
              }
              if(a.id!==this.$store.state.user.id){
                a.actions['edit'] = {
                  to:{name:'user_update', params:{userId:a.id}},
                  text: this.$t('words.edit')
                }
              }
              return a
            })
            this.admins.isLoaded = true

            this.farmers.data = res.data.farmers.map((f) => {
              f.nbDevices = f.nbSensors + f.nbTransmitters
              f.npaCity = `${f.npa} ${f.city}`
              f.actions = {
                see: {
                  to: {name: 'farmer_show', params:{id: f.id}},
                  text: this.$t('words.see')
                },
                edit:{
                  to:{name:'user_update', params:{userId:f.id}},
                  text: this.$t('words.edit')
                }
              }
              return f
            })
            this.farmers.isLoaded = true
          })
    }
  }
}
</script>

<style scoped>

</style>
