<template>
  <div>
    <h2>Landing</h2>
    <p>{{ this.$store.getters.count }}</p>
  </div>
</template>

<script>
export default {
  name: "Landing"
}
</script>

<style scoped>

</style>
