<template>
  <div>
    <div class="card">
      <div class="card__title">{{$t('pages.profile.update-password')}}</div>
      <div class="flex flex-wrap justify-center">
        <ChangePassword class="w-1/2" :user-id="this.$store.state.user.id"/>
      </div>
    </div>
    <div>
      <div class="flex flex-wrap justify-center mt-4 w-full">
        <UserUpdate :profile="true" :title="$t('pages.profile.update-info').toString()"/>
      </div>
    </div>
  </div>
</template>
<script>
import ChangePassword from "@/components/forms/ChangePassword.vue";
import UserUpdate from "@/views/app/user/UserUpdate.vue";

export default {
  name: "Profile",
  components: {UserUpdate, ChangePassword}
}
</script>
<style scoped>

</style>