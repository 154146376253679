<template>

  <div v-if="cultureIsLoaded" class="outline-none" tabindex="0" @keydown.esc="onEscapeDown">

    <!-- Modal launch when we want to close a culture -->
    <modal :visible="closeModal.display" :toggle="toggleCloseModal">
      <h1 class="underline">{{ $t('pages.culture_show.closeModal.title') }} <b>{{ culture.name }}</b></h1>
      <p class="italic">{{ $t('pages.culture_show.closeModal.subtitle') }}</p>

      <!-- Culture still have connected transmitters -->
      <div v-if="this.culture.transmitters.length > 0" class="mt-6">
        <p>{{ $t('pages.culture_show.closeModal.transfer.title') }}</p>

        <div>
          <input type="radio" id="transfer-to-pool" value="1" v-model.number="closeModal.userChoices.transferMode">
          <label for="transfer-to-pool"> {{ $t('pages.culture_show.closeModal.transfer.radioPool') }}</label>
        </div>
        <div>
          <input type="radio" id="transfer-existing-culture" value="2" v-model.number="closeModal.userChoices.transferMode" @click="loadSiblingsCultures">
          <label for="transfer-existing-culture"> {{ $t('pages.culture_show.closeModal.transfer.radioExistingCulture') }}</label>
          <div v-if="closeModal.userChoices.transferMode === 2" class="ml-5">

            <div v-if="!closeModal.isLoaded.cultures">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
            <div v-else>
              <select v-if="closeModal.cultures.length > 0" v-model.number="closeModal.userChoices.transferCulture" class="select-basic">
                <option v-for="culture in closeModal.cultures" :key="culture.id" :value="culture.id">{{ culture.name }}</option>
              </select>
              <span v-else class="italic">{{ $t('pages.culture_show.closeModal.transfer.noCultures') }}</span>
            </div>

          </div>
        </div>
        <div>
          <input type="radio" id="transfer-clone-culture" value="3" v-model.number="closeModal.userChoices.transferMode">
          <label for="transfer-clone-culture"> {{ $t('pages.culture_show.closeModal.transfer.radioCloneCulture') }}</label>
        </div>

      </div>
      <!-- Close the culture -->
      <div v-if="!(closeModal.userChoices.transferMode === 2 && closeModal.cultures.length === 0)" class="mt-4 text-center">
        <button @click="closeTheCulture" class="btn btn--danger"><i class="mr-2 fas fa-power-off"></i>{{ $t('pages.culture_show.close_btn') }}</button>
      </div>
    </modal>

    <!-- Full screen modal for map visualisation -->
    <full-screen-modal :visible="mapModal.isVisible" :toggle="toggleMapModal">
      <div class="w-full h-full relative">
        <interactive-map :isGeoMap="culture.hasGeoMap" :transmitters="culture.transmitters" :mapImageName="culture.mapImageName">
          <template v-slot:customControl>
            <div class="shadow-md text-right bg-white py-4 px-10 border border-gray-300 rounded-md">
              <h1 class="text-lg">{{ $t('pages.culture_show.interactiveMap.control.title') }} <b class="text-md">{{ culture.name }}</b>
                <router-link :to="{name: 'culture_map_edit'}">
                  <i class="text-lg ml-2 fas fa-edit text-yellow-400 cursor-pointer"></i>
                </router-link>
              </h1>
              <h2 class="text-sm">
                {{ $t('pages.culture_show.interactiveMap.control.subTitle') }} : {{ displayableTransmitters.length }}/{{ culture.transmitters.length }}
                <i v-if="displayableTransmitters.length !== culture.transmitters.length" class="ml-1 fas fa-exclamation-triangle text-yellow-400"></i>
                <i v-else class="ml-1 fas fa-check text-green-400"></i>
              </h2>
            </div>
          </template>
        </interactive-map>
      </div>
    </full-screen-modal>

    <!-- Buttons -->
    <div>
      <!-- Events-->
      <router-link :to="{name: 'culture_events', params: {id: culture.id}}">
        <button class="btn btn--primary relative">
          <span>{{ $t('pages.culture_show.events') }}</span>
          <span v-if="culture.nbPendingEvents > 0" class="badge -top-2.5 -right-2.5 ml-2">{{ culture.nbPendingEvents }}</span>
        </button>
      </router-link>

      <!-- CSV download-->
      <btn-with-loader v-if="culture.transmitters.length > 0" class="ml-4" :loading="loadingCSV" :class-btn-type="'btn--primary'" @clicked="downloadCsv">
        <i class="fas fa-download mr-2"></i> CSV
      </btn-with-loader>

      <!-- Close -->
      <button @click="toggleCloseModal" class="ml-4 btn btn--danger"><i class="mr-2 fas fa-power-off"></i>{{ $t('pages.culture_show.close_btn') }}</button>
    </div>

    <!-- Card details -->
    <div class="card mt-4">
      <div class="flex">
        <!-- Details-->
        <div>
          <h2 class="card__title">{{ $t('pages.culture_show.cards.details.title') }} <span class="font-semibold">"{{ culture.name }}"</span></h2>
          <div>
            <div class="flex justify-items-start">
              <div>
                <div>{{ $t('pages.culture_show.cards.details.cultivatedVariety') }}: </div>
                <div>{{ $t('pages.culture_show.cards.details.cultureType') }}: </div>
                <div>{{ $t('pages.culture_show.cards.details.nouaisonDate') }}: </div>
              </div>

              <div class="ml-4 font-semibold">
                <div>{{ this.$t('backend_trans_keys.'+culture.typeTransKey) + " " + this.$t('backend_trans_keys.'+culture.varietyTransKey) }}</div>
                <div>{{ culture.outside ? this.$t('words.outside') : this.$t('words.inside') }}</div>
                <div>{{ culture.nouaisonDate === null ? '-' : this.displayDate(culture.nouaisonDate) }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Map displaying (geographic or custom) -->
        <div v-if="culture.hasGeoMap !== null" @click="toggleMapModal" class="map-wrapper">
          <img v-if="culture.hasGeoMap === false" :src="apiUploadMapsRoute + '/' + culture.mapImageName">
          <img class="rounded-md" v-else src="../../../assets/images/real_maps_overlay.png" alt="">
          <div class="map-btn">{{ $t('pages.culture_show.cards.details.showMap') }}</div>
        </div>
      </div>
    </div>

    <!-- Card transmitters -->
    <div class="card mt-4">
      <h2 class="card__title">{{ $t('pages.culture_show.cards.transmitters.title', {"count": transmittersTable.data.length}) }}</h2>
      <data-table :columns="transmittersTable.columns" :data="transmittersTable.data" />
    </div>

    <div>
      <CultureChart :culture-id="id"/>
    </div>
  </div>


</template>

<script>
import DataTable from "../../../components/general/datatable/data-table";
import {
  apiGetRequest,
  apiPostRequest,
  displayAlertSuccess,
  displayDateByLocale,
  displayDateTimestampByLocale,
  displayElapsedTimeToTimestamp,
  generateDownloadCsvLink
} from "../../../utils";
import {
  API_CULTURES_CLOSE,
  API_CULTURES_MEASURES_CSVEXPORT_SUFFIX,
  API_CULTURES_SHOW,
  API_CULTURES_SHOW2,
  API_CULTURES_SIBLINGS,
  API_UPLOAD_MAPS_ROUTE,
} from "../../../utils/constants";
import BtnWithLoader from "../../../components/general/btn-with-loader";
import Modal from "../../../components/modals/modal";
import InteractiveMap from "../../../components/interactive-map/interactive-map";
import FullScreenModal from "../../../components/modals/full-screen-modal";
import CultureChart from "@/components/model/CultureChart.vue";

export default {
  name: "CultureShow",
  components: {CultureChart, FullScreenModal, InteractiveMap, Modal, BtnWithLoader, DataTable},
  props: {},
  data: function() {
    return {
      id: this.$route.params.id,
      cultureIsLoaded: false,
      culture : {},
      transmittersTable: {
        columns: {
          id: this.$t('words.id'),
          type: this.$t('words.type'),
          batteryLevel: this.$t('words.battery-level'),
          nbSensors: this.$t('words.nbSensors'),
          lastSeenAtElapsed: this.$t('words.active-last-time'),
          lastMeasureAtElapsed: this.$t('words.last-measure-at'),
          actions: this.$t('words.actions')
        },
        data: null,
      },
      loadingCSV: false,
      closeModal: {
        display: false,
        cultures: [],
        isLoaded: {
          cultures: false
        },
        userChoices: {
          transferMode: 1,
          transferCulture: -1
        }
      },
      mapModal: {
        isVisible: false
      },
      apiUploadMapsRoute: API_UPLOAD_MAPS_ROUTE,
    }
  },
  mounted() {
    this.loadCulture()
  },
  computed: {
    displayableTransmitters: function() {
      return this.culture.transmitters.filter(t => t.latitude !== null && t.longitude !== null)
    },
  },
  methods: {

    /**
     * Load culture info
     */
    loadCulture: function() {
      this.cultureIsLoaded = false

      // Fetch all culture info
      apiGetRequest(API_CULTURES_SHOW2(this.id))
          .then((res) => {
            this.culture = res.data
            this.transmittersTable.data = this.culture.transmitters.map((t) => {
              let lastBatteryAt = t.lastBatteryAt === null ? "" : "(" + this.displayDateTimeByTimestamp(t.lastBatteryAt) + ")"
              t.type = this.$t('backend_trans_keys.'+t.typeTransKey)
              t.batteryLevel = t.batteryPct == null ? this.$t('words.unknown') : t.batteryPct + " % " + lastBatteryAt
              t.lastSeenAtElapsed = t.lastSeenAt == null ? this.$t('words.never') : displayElapsedTimeToTimestamp(t.lastSeenAt)
              t.lastMeasureAtElapsed = t.lastMeasureAt == null ? this.$t('words.never') : displayElapsedTimeToTimestamp(t.lastMeasureAt)
              t.actions = {
                see: {
                  to: {name: 'transmitter_show', params:{id: t.id}},
                  text: this.$t('words.see')
                }
              }
              return t
            })

            this.cultureIsLoaded = true
          })
    },
    /**
     * Asynchronous call to generate csv export that contains all measures by this culture
     * For ajax call, we need to recreate a link and simulate a click to download the real file.
     */
    downloadCsv: function() {
      if(!this.loadingCSV){
        this.loadingCSV = true
        apiGetRequest(API_CULTURES_SHOW + this.id + API_CULTURES_MEASURES_CSVEXPORT_SUFFIX)
            .then((res) => {
              const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
              link.click();
            })
            .finally(() => this.loadingCSV = false)
      }
    },
    /**
     * Open or close the modal, depends on the modal.display value
     */
    toggleCloseModal: function() {
      this.closeModal.display = !this.closeModal.display
    },
    /**
     * Load all siblings cultures of the current one. (all active cultures for the user except the current one)
     */
    loadSiblingsCultures: function(){
      // Load cultures only if not already loaded
      if(this.closeModal.cultures.length === 0){
        this.closeModal.isLoaded.cultures = false
        apiGetRequest(API_CULTURES_SIBLINGS(this.culture.id))
            .then((res) => {
              this.closeModal.cultures = res.data
              this.closeModal.isLoaded.cultures = true
              // Pré-select the first available culture if there is at least one
              if(this.closeModal.cultures.length > 0){
                this.closeModal.userChoices.transferCulture = this.closeModal.cultures[0].id
              }
            })
            .catch(() => this.toggleCloseModal())
      }
    },
    /**
     * Close (clôture) the culture and transfer all still used transmitters if there are some,
     * depending on what the user has chosen
     */
    closeTheCulture: function() {
      apiPostRequest(API_CULTURES_CLOSE(this.culture.id), this.closeModal.userChoices)
          .then(() => {
            const successMsg = this.$t('pages.culture_show.closeModal.success', {name: this.culture.name})
            displayAlertSuccess(successMsg)
            // Redirect to right route and display a success message
            if(this.$store.getters.userIsAdmin){
              this.$router.push({name: "farmer_show", params: {id: this.culture.farmerId}})
            }else{
              this.$router.push({name: "culture_index"})
            }
          })
          .catch(() => this.toggleCloseModal())
    },
    /**
     * Display date by timestamp using moment.js locale
     * @param dateTimeTimestamp The date to display in timestamp
     * @returns {string} A string representation of the date, well formatted by the current locale
     */
    displayDateTimeByTimestamp: function(dateTimeTimestamp) {
      return displayDateTimestampByLocale(dateTimeTimestamp)
    },
    /**
     * Display date by moment.js locale
     * @param date The date to display
     * @returns {string} A strig representation of the date, well formatted by the current locale
     */
    displayDate: function(date) {
      return displayDateByLocale(date, true)
    },
    /**
     * Open or close the modal
     */
    toggleMapModal: function() {
      this.mapModal.isVisible = !this.mapModal.isVisible
    },
    onEscapeDown: function() {
      if(this.mapModal.isVisible){
        this.toggleMapModal()
      }
    },
  }
}
</script>

<style scoped>

.map-wrapper{
  @apply overflow-hidden relative ml-10 w-44 flex items-center cursor-pointer p-1 rounded-md;
}
.map-wrapper .map-btn {
  @apply absolute top-0 right-0 bg-blue-500 px-2 py-1 text-sm rounded-md text-white border-l border-b border-blue-600 font-semibold;
}
.map-wrapper:hover > .map-btn {
  @apply bg-blue-600;
}
.map-wrapper img{
  transition: transform .5s ease;
}
.map-wrapper:hover img {
  transform: scale(1.5);
}

</style>
