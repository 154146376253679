<template>
  <div>

    <!-- General Info -->
    <div v-if="isLoaded.generalInfo" class="card">
      <h2 class="card__title">{{ $t('pages.farmer_show.cards.general-info.title') }}</h2>

      <div class="flex justify-items-start">
        <div>
          <div>{{ $t('words.name') }}: </div>
          <div>{{ $t('words.email') }}: </div>
          <div>{{ $t('words.street') }}: </div>
          <div>{{ $t('words.npa-city') }}: </div>
        </div>

        <div class="ml-4 font-semibold">
          <div>{{ this.farmer.info.firstname }} {{ this.farmer.info.lastname }}</div>
          <div>{{ this.farmer.info.email }}</div>
          <div>{{ this.farmer.info.street }}</div>
          <div>{{ this.farmer.info.npa }} {{ this.farmer.info.city }}</div>
        </div>

        <div class="ml-10">

          <div class="flex">
            <!-- User inventory -->
            <router-link :to="{name: 'users_inventory', params: {userIdProps: this.farmerId}}">
              <button class="mb-4 btn btn--primary relative block">
                <span><i class="fas fa-list mr-2"></i>{{ $t('pages.farmer_show.cards.general-info.inventoryBtn') }}</span>
              </button>
            </router-link>

            <!-- Show events button -->
            <router-link class="ml-4" :to="{name: 'pendingEvent_index', query: {userId: this.farmerId}}">
              <button class="mb-4 btn btn--primary relative block">
                <span>{{ $t('pages.farmer_show.cards.general-info.events') }}</span>
                <span v-if="farmer.eventsPendingCount > 0" class="badge -top-2.5 -right-2.5 ml-2">{{ farmer.eventsPendingCount }}</span>
              </button>
            </router-link>

            <!-- Show all historic cultures button-->
            <router-link class="ml-4" :to="{name: 'history_farmerCultureIndex', params: {farmerIdProps: this.farmerId}}">
              <button class="btn btn--primary block">
                <i class="fas fa-history mr-2"></i>{{ $t('pages.history_farmerCultureIndex.title') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>

    </div>

    <div v-if="isLoaded.cultures" >
      <!-- New culture btn -->
      <router-link :to="{name: 'farmer_culture_create', params: { farmerId: this.farmerId }}">
        <button class="mt-4 btn btn--success"><i class="fas fa-plus mr-2"></i>{{ $t('pages.culture_index.new-culture') }}</button>
      </router-link>

      <!-- All its cultures -->
      <div class="card mt-4">
        <h2 class="card__title">{{ $t('pages.farmer_show.cards.cultures.title', {count: farmer.culturesTable.data.length}) }}</h2>
        <data-table :columns="farmer.culturesTable.columns" :data="farmer.culturesTable.data" />
      </div>
    </div>

  </div>
</template>

<script>

import {
  API_FARMERS_CULTURES_SUFFIX,
  API_FARMERS_SHOW,
  API_USERS_EVENTS_PENDING_COUNT_PREFIX,
  API_USERS_EVENTS_PENDING_COUNT_SUFFIX
} from "../../../../utils/constants";
import {apiGetRequest, displayDateByLocale} from "../../../../utils";
import DataTable from "../../../../components/general/datatable/data-table";

export default {
  name: "FarmerShow",
  components: {DataTable},
  props: {},
  data: function() {
    return {
      farmerId: this.$route.params.id,
      isLoaded: {
        generalInfo: false,
        cultures: false
      },
      farmer: {
        info: null,
        culturesTable : {
          columns: {
            name: this.$t('words.name'),
            cultivatedVariety: this.$t('pages.farmer_show.cards.cultures.columns.cultivatedVariety'),
            cultureType: this.$t('pages.farmer_show.cards.cultures.columns.cultureType'),
            nouaisonDate: this.$t('pages.farmer_show.cards.cultures.columns.nouaisonDate'),
            nbTransmitters: this.$t('words.nbTransmitters'),
            actions: this.$t('words.actions'),
          },
          data: null
        },
        eventsPendingCount: 0
      }
    }
  },
  // Fetch all info about the farmer and its cultures
  mounted() {
    this.isLoaded.generalInfo = false
    apiGetRequest(API_FARMERS_SHOW + this.farmerId)
        .then((res) => {
          this.farmer.info = res.data
          this.getEventsPendingCount()
          this.getAllCultures()
          this.isLoaded.generalInfo = true
        })
  },
  methods: {
    /**
     * Fetch the current pending events count for this current user
     */
    getEventsPendingCount: function(){
      apiGetRequest(API_USERS_EVENTS_PENDING_COUNT_PREFIX + this.farmerId + API_USERS_EVENTS_PENDING_COUNT_SUFFIX)
        .then((res) => {
          this.farmer.eventsPendingCount = res.data.count
        })
    },
    /**
     * Fetch all cultures of the farmer
     */
    getAllCultures: function(){
      this.isLoaded.cultures = false
      apiGetRequest(API_FARMERS_SHOW + this.farmerId + API_FARMERS_CULTURES_SUFFIX)
          .then((res) => {
            this.farmer.culturesTable.data = res.data.map((c) => {
              c.cultivatedVariety = `${this.$t('backend_trans_keys.'+c.typeTransKey)} (${this.$t('backend_trans_keys.'+c.varietyTransKey)})`
              c.cultureType = c.outside ? this.$t('words.outside') : this.$t('words.inside')
              c.nouaisonDate = c.nouaisonDate === null ? '-' : this.displayDate(c.nouaisonDate)
              c.actions = {
                see: {
                  to: {name: 'culture_show', params:{id: c.id}},
                  text: this.$t('words.see')
                },
                edit: {
                  to: {name: 'culture_edit', params:{id: c.id}},
                  text: this.$t('words.edit')
                }
              }
              return c
            })
            this.isLoaded.cultures = true
          })
    },
    /**
     * Display date by moment.js locale
     * @param date The date to display
     * @returns {string} A strig representation of the date, well formatted by the current locale
     */
    displayDate: function(date) {
      return displayDateByLocale(date, true)
    }
  }
}
</script>

<style scoped>

</style>
