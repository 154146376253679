<template>
  <div>
    <!-- Sensor details card -->
    <div v-if="isSensorLoaded" class="card">
      <h2 class="card__title">{{ $t('pages.sensor_show.cards.details.title') }} <span class="font-semibold">"{{ sensor.id }}"</span></h2>
      <div class="flex justify-items-start">
        <div>
          <div>{{ $t('pages.sensor_show.cards.details.type') }}: </div>
          <div>{{ $t('pages.sensor_show.cards.details.transmitter') }}: </div>
          <div>{{ $t('pages.sensor_show.cards.details.culture') }}: </div>
        </div>

        <div class="ml-4 font-semibold">
          <div>{{ $t('backend_trans_keys.'+sensor.typeTransKey) }}</div>
          <div>{{ sensor.transmitter === null ? $t('pages.sensor_show.cards.details.noneM') : `${sensor.transmitter.id} (${$t('backend_trans_keys.'+sensor.transmitter.typeTransKey)})` }}</div>
          <div>{{ sensor.culture === null ? $t('pages.sensor_show.cards.details.noneF') : `${sensor.culture.name} (${$t('backend_trans_keys.'+sensor.culture.typeTransKey)} ${$t('backend_trans_keys.'+sensor.culture.varietyTransKey)})` }}</div>
        </div>
      </div>
    </div>

    <!-- Buttons (refresh chart and download csv) -->
    <div v-if="isSensorLoaded && sensor.culture !== null" class="mt-4">
      <button v-if="selectedTimePeriod !== 5" @click="refreshCharts" class="mr-4 btn btn--primary"><i class="fas fa-sync" :class="{ 'fa-spin': loadingMeasures }"></i></button>
      <btn-with-loader :loading="loadingCSV" :class-btn-type="'btn--primary'" @clicked="downloadCsv"><i class="fas fa-download mr-2"></i>CSV</btn-with-loader>
    </div>

    <div v-if="isMeasuresLoaded">
      <!-- Time period selection -->
      <select-time-period :default-value="selectedTimePeriod" :default-show-data-picker="showDatePicker" :default-start-date="startDate" :default-end-date="endDate" @valueChanged="timePeriodChanged" @submitCustomDate="submitCustomPeriod" class="mt-3"/>


      <!-- All measures chart -->
      <div v-for="measureType in measureTypes" :key="measureType.id" class="card mt-5">
        <div class="flex justify-between">
          <h2 class="card__title">{{ $t('backend_trans_keys.'+measureType.typeTransKey) }}</h2>
        </div>

        <div class="overflow-x-auto">
          <div style="min-width: 600px">
            <highcharts :options="measureType.chartOptions" ref="lineCharts" constructor-type="chart"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {apiGetRequest, displayAlertError, generateDownloadCsvLink} from "../../../utils";
import {
  API_SENSORS_MEASURES_CSVEXPORT_SUFFIX,
  API_SENSORS_MEASURES_SUFFIX,
  API_SENSORS_SHOW
} from "../../../utils/constants";
import {Chart} from 'highcharts-vue'
import SelectTimePeriod from "../../../components/charts/SelectTimePeriod";
import {getFromAndToQueryParam, getFromQueryParam, getLineChartOptionsByMeasureType} from "../../../utils/chartUtils";
import btnWithLoader from "../../../components/general/btn-with-loader";


export default {
  name: "SensorShow",
  components: {
    SelectTimePeriod,
    highcharts: Chart,
    btnWithLoader: btnWithLoader
  },
  data: function() {
    return {
      id: this.$route.params.id,
      loadingCSV: false,
      isSensorLoaded: false,
      isMeasuresLoaded: false,
      loadingMeasures: false,
      sensor : {},
      measureTypes : {},
      selectedTimePeriod: 1,
      showDatePicker: false,
      startDate:"",
      endDate:"",
    }
  },
  mounted() {
    this.isSensorLoaded = false
    this.isMeasuresLoaded = false

    apiGetRequest(API_SENSORS_SHOW + this.id)
        .then((res) => {
          this.sensor = res.data
          this.isSensorLoaded = true
          // Load the charts only if the sensor is in a culture (so have a transmitter linked)
          if(this.sensor.culture !== null){
            this.refreshCharts()
          }
        })
  },
  methods: {
    timePeriodChanged: function(selectedTimePeriod){
      this.selectedTimePeriod = selectedTimePeriod
      if(selectedTimePeriod !== 5){
        this.refreshCharts()
        this.showDatePicker = false
      }else{
        this.showDatePicker = true
      }
    },
    submitCustomPeriod: function(rangeDates){
      this.startDate = rangeDates.start
      this.endDate = rangeDates.end
      const fromAndToQueryParams = getFromAndToQueryParam(rangeDates)
      this.refreshCharts2(fromAndToQueryParams)

    },

    refreshCharts: function() {
      const fromQueryParam = getFromQueryParam(this.selectedTimePeriod)
      this.refreshCharts2(fromQueryParam)
    },
    /**
     * Ask the API to fetch all measures according the selected time period, and display all charts.
     * We fetch each time the whole wanted data, not only the new one (if we refresh multiple time)
     * todo later : fetch only new data, according the last loaded value
     */
    refreshCharts2 : function(queryParams){
      if(!this.loadingMeasures) {
        this.isMeasuresLoaded = false
        this.measureTypes = {}
        this.loadingMeasures = true
        apiGetRequest(API_SENSORS_SHOW + this.id + API_SENSORS_MEASURES_SUFFIX + queryParams)
            .then((res) => {
              this.updateMeasureTypes(res.data)
              this.isMeasuresLoaded = true
            })
            .finally(() => this.loadingMeasures = false)
      }
    },
    /**
     * Update (extends) the local measureTypes variable to add all measures data provided by the API.
     * The updated value will contain all data and options for the chart displaying
     * @param data All measures data provided by the API
     */
    updateMeasureTypes: function(data){
      this.measureTypes = data

      for (const key in this.measureTypes) {
        const measureType = this.measureTypes[key]
        measureType["chartOptions"] = getLineChartOptionsByMeasureType(measureType, () => {
          // Error may be possible due to old browser version
          const errorMsg = this.$t('pages.sensor_show.error-export')
          displayAlertError(errorMsg)
        })
      }
    },
    /**
     * Asynchronous call to generate csv export that contains all measures for this sensor
     * For ajax call, we need to recreate a link and simulate a click to download the real file.
     */
    downloadCsv: function() {
      if(!this.loadingCSV){
        this.loadingCSV = true
        apiGetRequest(API_SENSORS_SHOW + this.id + API_SENSORS_MEASURES_CSVEXPORT_SUFFIX)
            .then((res) => {
              const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
              link.click();
            })
            .finally(() => this.loadingCSV = false)
      }
    }
  }
}
</script>

<style scoped>

</style>
