export const ADMIN_ROLE = 'admin'
export const FARMER_ROLE = 'farmer'
export const INSTALLER_ROLE = 'installer'
export const AlertTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger'
}
export const N_MAX_MODEL_TRAINED = +process.env.VUE_APP_N_MAX_MODEL_TRAINED
export const EVENT_TYPE_INACTIVE_TRANSMITTER = "INACTIVE_TRANSMITTER"
export const EVENT_TYPE_NEW_TRANSMITTER = "NEW_TRANSMITTER"
export const EVENT_TYPE_SENSOR_MANAGEMENT = "SENSOR_MANAGEMENT"

export const EVENT_SENSOR_ITEM_TYPE_ADD = "ADD_SENSOR"
export const EVENT_SENSOR_ITEM_TYPE_REMOVE = "REMOVE_SENSOR"
export const EVENT_SENSOR_ITEM_TYPE_REPLACE = "REPLACE_SENSOR"

export const BASE_API = process.env.VUE_APP_BASE_API_SERVER_URL + "/api"
export const BASE_API_WS = process.env.VUE_APP_BASE_WEBSOCKET_SERVER_URL + "/ws"
export const API_UPLOAD_MAPS_ROUTE = process.env.VUE_APP_BASE_API_SERVER_URL+"/upload/maps"

// Login
export const API_LOGIN = BASE_API + "/login"
// Tokens
export const API_REFRESH_TOKEN = BASE_API + "/token/refresh"
// Users
export const API_USERS_INDEX = BASE_API + "/users"
export const API_USERS_CREATE = API_USERS_INDEX + "/create"
export const API_USERS_ALL = API_USERS_INDEX
export const API_USERS_EVENTS_PENDING_COUNT_PREFIX = API_USERS_INDEX + "/"
export const API_USERS_EVENTS_PENDING_COUNT_SUFFIX = "/events/pending/count"
export function API_USERS_TRANSMITTERS_ALL(userId) {
  return API_USERS_INDEX + "/" + userId + "/transmitters"
}
export function API_USERS_TRANSMITTERS_STORE(userId) {
  return API_USERS_TRANSMITTERS_ALL(userId) + "/store"
}
export function API_USERS_SENSORS_ALL(userId) {
  return API_USERS_INDEX + "/" + userId + "/sensors"
}
export function API_USERS_SENSORS_STORE(userId) {
  return API_USERS_SENSORS_ALL(userId) + "/store"
}
export function API_USERS_SPECIFIC_SHOW(userId) {
  return `${API_USERS_INDEX}/${userId}`
}
export function API_USERS_UPDATE(userId) {
  return `${API_USERS_SPECIFIC_SHOW(userId)}/update`
}
export function API_USERS_DELETE(userId) {
  return `${API_USERS_SPECIFIC_SHOW(userId)}/delete`
}
export function API_USERS_RESET_PASSWORD(userId) {
  return `${API_USERS_SPECIFIC_SHOW(userId)}/reset`
}
export function API_USERS_CHANGE_PASSWORD(userId) {
  return `${API_USERS_SPECIFIC_SHOW(userId)}/password/update`
}
// Farmers
export const API_FARMERS_INDEX = BASE_API + "/farmers"
export const API_FARMERS_SHOW = API_FARMERS_INDEX + "/" // :id
export const API_FARMERS_CULTURES_SUFFIX = "/cultures"
export const API_FARMERS_CULTURES_ARCHIVED_SUFFIX = API_FARMERS_CULTURES_SUFFIX + "/archived"
export function API_FARMERS_CULTURES(farmerId) {
  return API_FARMERS_INDEX + "/" + farmerId + "/cultures"
}

// Cultures
export const API_CULTURES_INDEX = BASE_API + "/cultures"
export const API_CULTURES_STORE = API_CULTURES_INDEX
export const API_CULTURES_SHOW = API_CULTURES_INDEX + "/" // :id
export const API_CULTURES_PENDING_EVENTS_PREFIX = "/pending-events"
export const API_CULTURES_PENDING_EVENTS_COUNT_PREFIX =  API_CULTURES_PENDING_EVENTS_PREFIX + "/count"
export const API_CULTURES_MEASURES_CSVEXPORT_SUFFIX =  "/measures/csv-export" // :id
export const API_CULTURES_TRANSMITTERS_SUFFIX =  "/transmitters" // :id
export function API_CULTURES_SHOW2(id) {
  return API_CULTURES_INDEX + "/" + id
}
export function API_CULTURES_SIBLINGS(id) {
  return API_CULTURES_SHOW2(id) + "/siblings"
}
export function API_CULTURES_CLOSE(id) {
  return API_CULTURES_SHOW2(id) + "/close"
}
export function API_CULTURES_UPDATE(id) {
  return API_CULTURES_SHOW2(id) + "/update"
}

// CULTURE HUNT
export function API_CULTURES_GLOBAL(id) {
  return API_CULTURES_SHOW2(id) + "/global"
}
export function API_CULTURES_GLOBAL_DIAMETER(id) {
  return API_CULTURES_GLOBAL(id) + "/diameter"
}
export function API_CULTURES_GLOBAL_HUNT(id) {
  return API_CULTURES_GLOBAL(id) + "/hunt"
}
export function API_CULTURES_GLOBAL_HUNT_FIT(id) {
  return API_CULTURES_GLOBAL(id) + "/hunt/fit"
}
export function API_CULTURES_GLOBAL_HUNT_DELETE(id) {
  return `${BASE_API}/global/hunt/${id}/delete`
}

//MODEL
export function API_CULTURES_MODEL(id) {
  return API_CULTURES_SHOW2(id) + "/models"
}
export function API_CULTURES_MODEL_DELETE(id) {
  return `${BASE_API}/models/${id}/delete`
}

//PREDICTION
export function API_CULTURES_PREDICT(id) {
  return API_CULTURES_SHOW2(id) + "/predict"
}

// CULTURE_TYPES
export const API_CULTURE_TYPES_INDEX = BASE_API + "/culture-types"

// Transmitters
export const API_TRANSMITTERS_INDEX = BASE_API + "/transmitters"
export const API_TRANSMITTERS_SHOW = API_TRANSMITTERS_INDEX + "/" // :id
export const API_TRANSMITTERS_SENSORS_SUFFIX = "/sensors" // :id
export const API_TRANSMITTERS_MEASURES_SUFFIX = "/measures" // :id
export const API_TRANSMITTERS_MEASURES_CSVEXPORT_SUFFIX =  "/measures/csv-export" // :id
export const API_TRANSMITTERS_COORDS_UPDATE =  API_TRANSMITTERS_INDEX + "/coords/update"
export function API_TRANSMITTERS_SHOW2(id) {
  return API_TRANSMITTERS_INDEX + "/" + id
}
export function API_TRANSMITTERS_REMOVE_FROM_CULTURE(id) {
  return API_TRANSMITTERS_SHOW2(id) + "/remove"
}

// Transmitter types
export const API_TRANSMITTER_TYPES_ALL = BASE_API + "/transmitter-types"

// Sensors
export const API_SENSORS_INDEX = BASE_API + "/sensors"
export const API_SENSORS_SHOW = API_SENSORS_INDEX + "/" // :id
export const API_SENSORS_MEASURES_SUFFIX = "/measures" // :id
export const API_SENSORS_MEASURES_CSVEXPORT_SUFFIX = API_SENSORS_MEASURES_SUFFIX + "/csv-export" // :id

// Sensor types
export const API_SENSOR_TYPES_ALL = BASE_API + "/sensor-types"

// Events
export const API_EVENTS_INDEX = BASE_API + "/events"
export const API_EVENTS_ALL_PENDING_COUNT = API_EVENTS_INDEX + "/pending/count"
export const API_EVENTS_SHOW = API_EVENTS_INDEX + "/" // :id
export const API_EVENTS_SIMPLE_ACK_SUFFIX = "/ack"
export const API_EVENTS_ACK_NEW_TRANSMITTER_SUFFIX = API_EVENTS_SIMPLE_ACK_SUFFIX + "/new-transmitter"
export const API_EVENTS_ACK_INACTIVE_TRANSMITTER_SUFFIX = API_EVENTS_SIMPLE_ACK_SUFFIX + "/inactive-transmitter"
export const API_EVENTS_ACK_SENSOR_MANAGEMENT_SUFFIX = API_EVENTS_SIMPLE_ACK_SUFFIX + "/sensor-management"
export const API_EVENTS_PENDING = API_EVENTS_INDEX + "/pending"

// Measures
export const API_MEASURES_INDEX = BASE_API + "/measures"
export const API_MEASURES_DELETE = API_MEASURES_INDEX + "/delete"
export const API_MEASURES_MOVE = API_MEASURES_INDEX + "/move"
export const API_MEASURES_SERIES = API_MEASURES_INDEX + "/series"

// History
export const API_HISTORY_INDEX = BASE_API + "/history"
export const API_HISTORY_CULTURES = API_HISTORY_INDEX + "/cultures"
export function API_HISTORY_CULTURES_SHOW(cultureId) {
  return API_HISTORY_CULTURES + "/" + cultureId
}
export function API_HISTORY_CULTURES_TRANSMITTERS(cultureId) {
  return API_HISTORY_CULTURES_SHOW(cultureId) + "/transmitters"
}
export function API_HISTORY_CULTURES_TRANSMITTERS_SHOW(cultureId, transmitterId) {
  return API_HISTORY_CULTURES_TRANSMITTERS(cultureId) + "/" + transmitterId
}
export function API_HISTORY_CULTURES_TRANSMITTERS_SENSORS(cultureId, transmitterId) {
  return API_HISTORY_CULTURES_TRANSMITTERS_SHOW(cultureId, transmitterId) + "/sensors"
}
export function API_HISTORY_FARMERS_CULTURES(farmerId){
  return API_HISTORY_INDEX + "/farmers/" + farmerId + "/cultures"
}
export function API_HISTORY_FARMERS_CULTURES_SHOW(farmerId, cultureId){
  return API_HISTORY_FARMERS_CULTURES(farmerId) + "/" + cultureId
}
export function API_HISTORY_FARMERS_CULTURES_SERIES(farmerId, cultureId){
  return API_HISTORY_FARMERS_CULTURES_SHOW(farmerId, cultureId) + "/series"
}
export function API_HISTORY_FARMERS_CULTURES_SERIES_CSV_EXPORT(farmerId, cultureId){
  return API_HISTORY_FARMERS_CULTURES_SERIES(farmerId, cultureId) + "/csv-export"
}
export function API_HISTORY_SERIES_SHOW(measureUuid, fromQueryParam){
  return API_HISTORY_INDEX + "/series/" + measureUuid + fromQueryParam
}
export function API_HISTORY_SERIES_CSV_EXPORT(measureUuid){
  return API_HISTORY_SERIES_SHOW(measureUuid, "") + "/csv-export"
}
export function API_HISTORY_SERIES_DELETE(measureUuid){
  return API_HISTORY_SERIES_SHOW(measureUuid, "") + "/delete"
}

// WebSocket (routes/subscriptions/messages)
export const WS_PREDICT_RESULT="/user/predict/result"
export const WS_PREDICT_INFO="/user/predict"
export const WS_PREDICT_START="/predict"
export const WS_FIT_RESULT="/user/fit/result"
export const WS_FIT_INFO="/user/fit"
export const WS_FIT_START="/fit"

// Transfer
export const API_TRANSFER_BASE = BASE_API + "/transfer"

// Server logs
export const API_SERVER_LOGS = BASE_API + "/logs"
